.ant-form-item {
  margin-bottom: 10px;
}

.ant-menu-item-group-title {
  padding-top: 30px !important;
}
.submenu-title-wrapper {
  font-size: 18px;
}

.ant-btn-sm i {
  font-size: 10px;
  position: relative;
  bottom: 1px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}
